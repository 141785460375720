import React, { useState, useEffect } from 'react';
import { Select } from 'antd';

const CustomSelect = ({
  options,
  name,
  placeholder,
  value,
  onBlur,
  onChange,
  disabled
}) => {
  const [selectedValue, setSelectedValue] = useState(value);

  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  const handleChange = (newValue) => {
    setSelectedValue(newValue); // Update selectedValue state
    if (onChange) {
      onChange(newValue); // Call the onChange callback, if provided
    }
  };

  return (
    <div className="custome-select">
      <Select
        showSearch
        optionFilterProp="children"
        placeholder={placeholder}
        filterOption={(input, option) => option?.label?.toLowerCase().includes(input.toLowerCase())}
        // filterSort={(optionA, optionB) =>
        //   optionA?.label?.localeCompare(optionB?.label, undefined, { sensitivity: 'base' })
        // }
        options={options}
        value={selectedValue} // Use selectedValue as the value prop
        onChange={handleChange}
        name={name} // Pass the name prop to the Select component
        onBlur={onBlur} // Pass the onBlur prop to the Select component
        disabled={disabled}
      />
    </div>
  );
};

export default CustomSelect;
