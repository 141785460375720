import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Dropdown, Menu } from 'antd';
import './DatePicker.css';

const DateRangePicker = ({ startDate, endDate, onDateChange }) => {
    const [showDropdown, setShowDropdown] = useState(false);
    const [showCalendar, setShowCalendar] = useState(false);
    const [tempStartDate, setTempStartDate] = useState(startDate);
    const [tempEndDate, setTempEndDate] = useState(endDate);

    const handlePresetChange = (preset) => {
        const today = new Date();
        let newStartDate, newEndDate;

        switch (preset) {
            case 'today':
                newStartDate = newEndDate = today;
                break;
            case 'monthToday':
                newStartDate = new Date(today.getFullYear(), today.getMonth(), 1);
                newEndDate = today;
                break;
            case 'yearToday':
                newStartDate = new Date(today.getFullYear(), 0, 1);
                newEndDate = today;
                break;
            case 'lastMonth':
                newStartDate = new Date(today.getFullYear(), today.getMonth() - 1, 1);
                newEndDate = new Date(today.getFullYear(), today.getMonth(), 0);
                break;
            case 'custom':
                setShowCalendar(true);
                setShowDropdown(false); // Hide dropdown when "Custom" is selected
                return; // Custom range will be handled by the date picker itself
            case 'todayFuture':
                newStartDate = today;
                newEndDate = new Date(today.getFullYear() + 1, today.getMonth(), today.getDate());
                break;
            default:
                return;
        }

        onDateChange(newStartDate, newEndDate);
        setShowDropdown(false); // Hide dropdown after selecting a preset
    };

    const handleDropdownClick = () => {
        setShowDropdown(!showDropdown);
        setShowCalendar(false); // Ensure calendar is hidden when dropdown is shown
    };

    const handleCalendarClick = (e) => {
        e.stopPropagation(); // Prevent calendar clicks from closing dropdown
    };

    const handleStartDateChange = (date) => {
        setTempStartDate(date);
        setTempEndDate(null); // Clear end date when start date changes
    };

    const handleEndDateChange = (date) => {
        setTempEndDate(date);
        if (tempStartDate && date) {
            onDateChange(tempStartDate, date);
            setShowCalendar(false); // Close calendar after selecting both dates
        }
    };

    const menu = (
        <Menu>
            <Menu.Item onClick={() => handlePresetChange('today')}>Today</Menu.Item>
            <Menu.Item onClick={() => handlePresetChange('todayFuture')}>Today and Future</Menu.Item>
            <Menu.Item onClick={() => handlePresetChange('monthToday')}>Month to Date</Menu.Item>
            <Menu.Item onClick={() => handlePresetChange('yearToday')}>Year to Date</Menu.Item>
            <Menu.Item onClick={() => handlePresetChange('lastMonth')}>Last Calendar Month</Menu.Item>
            <Menu.Item onClick={() => handlePresetChange('custom')}>Custom</Menu.Item>
        </Menu>
    );

    return (
        <div className="date-range-picker" onClick={(e) => e.stopPropagation()}>
            <Dropdown
                overlay={menu}
                trigger={['click']}
                visible={showDropdown}
                onVisibleChange={(visible) => setShowDropdown(visible)}
            >
                <a className="ant-dropdown-link text-decoration-none" onClick={handleDropdownClick}>
                    <div className="date-picker-input">
                        <input
                            type="text"
                            readOnly
                            placeholder="Select date range"
                            value={`${startDate ? startDate.toLocaleDateString() : ''} - ${endDate ? endDate.toLocaleDateString() : ''}`}
                            onClick={handleDropdownClick}
                        />
                        <i className="fa fa-calendar date-picker-icons" />
                    </div>
                </a>
            </Dropdown>
            {showCalendar && (
                <div className="date-range-calendar" onClick={(e) => e.stopPropagation()}>
                    <DatePicker
                        selected={tempStartDate}
                        onChange={handleStartDateChange}
                        startDate={tempStartDate}
                        endDate={tempEndDate}
                        selectsStart
                        placeholderText="Select start date"
                        dateFormat="MM-dd-yyyy"
                        className="form-control"
                        onClick={handleCalendarClick} // Prevent clicks from closing calendar
                        open={showCalendar} // Show calendar
                        inline
                    />
                    <DatePicker
                        selected={tempEndDate}
                        onChange={handleEndDateChange}
                        startDate={tempStartDate}
                        endDate={tempEndDate}
                        selectsEnd
                        minDate={tempStartDate}
                        placeholderText="Select end date"
                        dateFormat="MM-dd-yyyy"
                        className="form-control"
                        onClick={handleCalendarClick} // Prevent clicks from closing calendar
                        open={showCalendar} // Show calendar
                        inline
                    />
                </div>
            )}
        </div>
    );
};

export default DateRangePicker;
